import React from "react"
import { Helmet } from "react-helmet"
import Header from "../components/header"
import Footer from "../components/footer"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import "../styles/style.css"
import Web from "../../static/images/icons/web/icon29.svg"
import Comunicaciones from "../../static/images/icons/web/icon30.svg"
import Comercio from "../../static/images/icons/web/icon31.svg"
import RRSS from "../../static/images/icons/web/icon32.svg"
import Ciberseguridad from "../../static/images/icons/web/icon33.svg"
import {BsArrowRight} from "react-icons/bs"
import Icon1 from "../../static/images/icons/web/icon4.svg"
import Icon2 from "../../static/images/icons/web/icon23.svg"
import Icon3 from "../../static/images/icons/web/icon19.svg"
import Icon4 from "../../static/images/icons/web/icon8.svg"
import Icon5 from "../../static/images/icons/web/icon25.svg"
import Icon6 from "../../static/images/icons/web/icon24.svg"

const SolucionesDigitales = () => {
  const data = useStaticQuery(graphql`
    query Images_soluciones {
      image: allFile(
        filter: { relativeDirectory: { eq: "kit-digital" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Soluciones Digitales para Empresas Kit Digital | Qualoom</title>
        <meta name="title" content="Soluciones Digitales para Empresas Kit Digital | Qualoom"/>
        <meta name="description" content="Conoce las soluciones del Kit Digital: Sitio web, presencia en Internet, Comunicaciones Seguras, Comercio electrónico, Redes Sociales y Ciberseguridad."/>
        <html lang='es'/>        
        <link rel="alternate" href="https://www.qualoom.es/soluciones-kit-digital-qualoom/"/>
        <link rel="canonical" href="https://www.qualoom.es/soluciones-kit-digital-qualoom/"/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.qualoom.es/soluciones-kit-digital-qualoom/"/>
        <meta property="og:title" content="Soluciones Digitales para Empresas Kit Digital | Qualoom"/>
        <meta property="og:description" content="Conoce las soluciones del Kit Digital: Sitio web, presencia en Internet, Comunicaciones Seguras, Comercio electrónico, Redes Sociales y Ciberseguridad."/>
        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3HfLvuBQ9MxEIS7c3EUWkm/0b919be153291f022b237bb833ebff63/tw_cards_kit-digital.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>
        <meta property="fb:admins" content="338114870907726"/>
        
        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://www.qualoom.es/soluciones-kit-digital-qualoom/"/>
        <meta property="twitter:title" content="Soluciones Digitales para Empresas Kit Digital | Qualoom"/>
        <meta property="twitter:description" content="Conoce las soluciones del Kit Digital: Sitio web, presencia en Internet, Comunicaciones Seguras, Comercio electrónico, Redes Sociales y Ciberseguridad."/>
        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3HfLvuBQ9MxEIS7c3EUWkm/0b919be153291f022b237bb833ebff63/tw_cards_kit-digital.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>

        <script type="application/ld+json">
        {`{"@type": "BreadcrumbsList", "@id":"https://www.qualoom.es/soluciones-kit-digital-qualoom/#breadcrumb",              
            "itemListElement":[
              {"@type":"ListItem", "position":"1", "name":"Inicio", "item": {"@id": "https://www.qualoom.es/", "name": "Inicio"}},
              {"@type":"ListItem", "position":"2", "name":"Soluciones Digitales", "item": {"@id": "https://www.qualoom.es/soluciones-kit-digital-qualoom/", "name": "Soluciones Digitales"}}
            ]}`}
        </script>
      </Helmet>
      <BackgroundImage fluid={data.image.nodes[0].childImageSharp.fluid}>
        <div className="background-kit">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>Soluciones Digitales</h1>
              <p>
                Descubre todas las soluciones digitales que ofrecemos en Qualoom
              </p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="services-main">
        <div className="services-main-content">
          <h2>¿Qué soluciones digitales ofrecemos en Qualoom?</h2><br/>
          <div className="soluciones-list-items">
            <a href="/kit-digital/soluciones-web/" className="soluciones-item">
              <img src={Web} alt="Sitio web y presencia en internet"></img>
              <h3>Sitio web y presencia en internet</h3>
              <span>Expansión de la presencia en internet de tu empresa mediante la creación de una página web y/o la prestación de servicios que proporcionen posicionamiento básico en internet.</span>
              <span>Descubre más sobre esta solución<BsArrowRight/></span>
            </a>
            <a href="/kit-digital/comunicaciones-seguras/" className="soluciones-item">
              <img src={Comunicaciones} alt="Comunicaciones seguras"></img>
              <h3>Comunicaciones seguras</h3>
              <span>Proporcionar a la empresa beneficiaria seguridad en las conexiones entre los dispositivos de sus empleados y la empresa.</span>
              <span>Descubre más sobre esta solución<BsArrowRight/></span>
            </a>
            <a href="/kit-digital/comercio-electronico/" className="soluciones-item">
              <img src={Comercio} alt="Comercio electrónico"></img>
              <h3>Comercio electrónico</h3>
              <span>Creación de una tienda online de compraventa de productos y/o servicios que utilice medios digitales para su intercambio.</span>
              <span>Descubre más sobre esta solución<BsArrowRight/></span>
            </a>
            <a href="/kit-digital/redes-sociales/" className="soluciones-item">
              <img src={RRSS} alt="Gestión de Redes Sociales"></img>
              <h3>Gestión de Redes Sociales</h3>
              <span>Promoción de la empresa beneficiaria en redes sociales.</span>
              <span>Descubre más sobre esta solución<BsArrowRight/></span>
            </a>
            <a href="/kit-digital/ciberseguridad/" className="soluciones-item">
              <img src={Ciberseguridad} alt="Ciberseguridad"></img>
              <h3>Ciberseguridad</h3>
              <span>Proporcionar a la empresa beneficiaria seguridad básica y avanzada para los dispositivos de sus empleados.</span>
              <span>Descubre más sobre esta solución<BsArrowRight/></span>
            </a>
          </div>
        </div>
      </div>
      <div className="services-main gray">
        <div className="services-main-content">
          <h2>¿Cómo podemos ayudarte en Qualoom?</h2>
          <div className="help-list-items">
            <div className="help-item"><img src={Icon1} alt="Asesoramiento"></img><p>Con nuestros servicios de asesoramiento y digitalización obtendrás los mejores resultados para tu empresa</p></div>
            <div className="help-item"><img src={Icon2} alt="Soluciones digitales"></img><p>Te ofrecemos los mejores servicios y soluciones tecnológicas para tus necesidades de digitalización</p></div>
            <div className="help-item"><img src={Icon3} alt="Funcionalidad"></img><p>Te garantizamos una correcta funcionalidad en todas las soluciones digitales que ofrecemos</p></div>
            <div className="help-item"><img src={Icon4} alt="Equipo cualificado"></img><p>Disponemos de un equipo altamente cualificado en los procesos de digitalización</p></div>
            <div className="help-item"><img src={Icon5} alt="Contenidos y recursos"></img><p>Tendrás a tu disposición varios contenidos y recursos para empresas y autónomos</p></div>
            <div className="help-item"><img src={Icon6} alt="Soporte"></img><p>Te sentirás acompañado durante todo el proceso de digitalización</p></div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default SolucionesDigitales
